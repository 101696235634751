<template>
  <div class="personalFi-confirmation">
    <Header v-bind:type="type" />
    <Breadcrumb_personalFi />
    <MainId />
    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">
            <span class="bold-title">Personal Financial Statement</span> as of
            <span id="current_date"></span>
          </div>

          <h1 class="section-title page-title">Disclosures</h1>

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div>
            By signing below, all signors ('you" and 'your") certify to First
            Hawaiian Bank (we", "us" and "our") that all information in this
            Statement is true and correct. As long as you owe any amount to us,
            you agree to give us prompt written notice of any material change in
            your financial condition, and upon our request, you agree to provide
            us with an updated Statement. You authorize us to retain this
            Statement even if we do not extend any credit to you and you
            authorize us to verify your credit and employment history and any
            information in this Statement. Our acceptance of this Statement does
            not obligate us to make any loan or extend credit even if you meet
            the standards we normally consider in determining whether to approve
            or deny a credit application.
          </div>

          <div class="v-spacer-30" />

          <table>
            <tr>
              <th scope="col" class="signature-spacing bottom-border">
                <label for="applicant-signature"
                  >Signature<sup class="fhb-red">*</sup></label
                >
              </th>
              <th scope="col" class="date-spacing bottom-border">
                Date<sup class="fhb-red">*</sup>
              </th>
            </tr>
            <td>
              <input
                id="applicant-signature"
                maxlength="512"
                class="ll-textfield signature"
                name="signature"
                aria-describedby="applicant-signature-error"
                :placeholder="this.signPlaceHolder"
                data-test="business-fname"
                type="text"
                v-model="appSignature"
                :class="
                  missingAppSignature ? 'll-textfield-error' : 'll-textfield'
                "
                v-on:blur="validateAppSignature"
              />
              <div id="applicant-signature-error" class="ind-err-container">
                <div
                  v-if="missingAppSignature"
                  class="form-error-msg"
                  data-test="business-fname-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">
                    Please sign your name
                  </div>
                </div>
              </div>
            </td>
            <td class="date-text">
              <span id="current_date2"></span>
            </td>
          </table>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />

          <div>
            IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To
            help the government fight the funding of terrorism and money
            laundering activities, Federal law requires all financial
            institutions to obtain. verify, and record information that
            identifies each person who opens an account. What this means for
            you: When you open an account, we will ask for your name, address,
            date of birth, and other information that will allow us to identify
            you. We may also ask to see your driver's license or other
            identifying documents.
          </div>
          <div class="v-spacer-20" />
          <div>
            Notice of Right to Receive Copy of Appraisal Report. If the loan or
            line of credit you are applying for will be secured by a first lien
            on a dwelling, we may order an appraisal to determine your
            property's value in connection with your application for credit, and
            charge you for the appraisal. We will promptly give you a copy of
            any appraisal, even if your application is denied or withdrawn. You
            can pay for an additional appraisal for your own use at your own
            cost.
          </div>

          <div class="v-spacer-50" />
          <div v-if="this.business_credit_id">
            <h2 class="section-title">Commercial Loan Appraisal Notice</h2>
            <div class="signature-box">
              <span
                >Notice of Right to Receive Copy of Appraisal Report. If the
                loan or line of credit you are applying for will be secured by a
                first lien on a dwelling, we may order an appraisal to determine
                your property's value in connnection with your application for
                credit, and charge you for the appraisal. We will promptly give
                you a copy of any appraisal, even if your application is denied
                or withdrawn. You will have a minimum of three business days to
                review your appraisal before you sign your credit documents. You
                can pay for an additional appraisal for your own use at your own
                cost.</span
              >
            </div>

            <div class="v-spacer-40" />

            <div class="description">
              Guaranty (All owner(s), members, and/or general partner(s) must
              complete. Attach a separate sheet if necessary.)
            </div>
            <div class="signature-box">
              <div class="txt-row">
                <div class="txt-column">
                  To induce First Hawaiian Bank ("Bank") or First Hawaiian
                  Leasing, Inc. ("FHL") to enter into a Promissory Note,
                  Business FirstLine Agreement, a business Yes-Check Agreement,
                  a Priority Destinations World Elite Business Program
                  Agreement, a Cash Rewards business Credit Card Agreement, a
                  Cash Rewards Business Credit Card Agreement, a Priority
                  Rewards Business Credit Card Agreement, a Priority Rewards
                  Business Program Agreement, a Business Lease Agreement, a
                  FirstLease Agreement, a Business Mastercard Credit Card
                  Agreement, and/or all other applicable agreements with
                  Applicant for the business credit products requested and
                  approved, the undersigned, jointly and severally, if there are
                  more than one signer (the "Guarantor") (i) unconditionally and
                  irrevocably guarantee(s) payment and performance (not of
                  collection) to Bank of any and all amounts owed to FHB,
                  including but not limited to all credit, advances, debts,
                  obligations and liabilities of the Applicant to Bank, under
                  any or all the foregoing agreements, however arising, whether
                  due or not due, absolute or contingent, liquidated or
                  unliquidated (the "Indebtedness"), as well as any extensions,
                  increases, modifications, or renewals of such Indebtedness,
                  (ii) waives notice of acceptance presentment, demand, protest,
                  notice of nonperformance, and any other motive of any kind
                  whatsoever with respect to the Indebtedness, and lack of
                  promptness does not waive the making of any claim or demand
                  hereunder, (iii) waives notice of the existence, creation or
                  incurring of new or additional Indebtedness, (iv) waives
                  notice of renewal, extension, acceleration, change of payment
                  terms, change or release of security, or release or
                  substitution of any one or more Guarantors,
                </div>

                <div class="txt-column">
                  (v) waives any right to require Bank to proceed against
                  Applicant or any other person or entity, proceed against or
                  exhaust any security held by Applicant, or pursue any other
                  remedy in Bank's power whatsoever, (vi) agrees there is no
                  right of subrogation (unless all Indebtedness from Applicant
                  to Bank has been paid in fill), and waives any right to
                  enforce any remedy which Bank now has or may hereafter have
                  against Applicant or any other person or entity, and any
                  benefit of, and any right to participate in, any security now
                  or hereafter held by Bank, (vii) waives the benefit of any
                  statute of limitation affecting liability hereunder or
                  enforcement thereof, (viii) agrees that Bank shall have a lien
                  upon and a right to setoff against all moneys, securities, and
                  other property of Guarantor now or hereafter in the possession
                  of or on deposit with Bank, (ix) agrees any Indebtedness now
                  or hereafter held by Guarantor is hereby subordinated to the
                  Indebtedness of Applicant to Bank, and (x) agrees to pay all
                  costs incurred by Bank in the or all of the Indebtedness,
                  including reasonable attorneys' fees. Guarantor hereby agrees
                  that this is a continuing Guaranty relating to any
                  Indebtedness, including that arising under successive
                  transactions, which shall either continue the Indebtedness or
                  from time to time renew it after it has been satisfied. If
                  this Application is not approved, then this Guaranty shall
                  have no force or effect. Guarantor authorizes Bank and FHL
                  both now and in the future, to obtain consumer credit reports
                  in his/her name(s) as individuals and to provide credit
                  bureaus and others with information about Bank's experience
                  with him/her.
                </div>
                <div class="v-spacer-30" />
                <div>
                  <br /><br /><br />By signing below, each person signing this
                  Guaranty confirms that s/he has reviewed and agrees to the
                  Terms and Conditions that appear on this Application.
                </div>
              </div>
            </div>

            <div class="v-spacer-50" />

            <div class="ll-item ll-fname row1-container">
              <div class="tf-label">
                <label for="applicant-id-input"
                  >Initial (to acknowledge personal liability on the account as
                  described above)<sup class="fhb-red">*</sup></label
                >
              </div>

              <div>
                <input
                  maxlength="2"
                  id="applicant-id-input"
                  aria-describedby="applicant-id-error"
                  :placeholder="`${this.applicant_first_name.charAt(
                    0
                  )}${this.applicant_last_name.charAt(0)}`"
                  class="ll-textfield tf-fname signature"
                  :data-test="`ll-bc-owner-initials`"
                  type="text"
                  name="initials"
                  v-model="appInitials"
                  :class="
                    missingInitials ? 'll-textfield-error' : 'll-textfield'
                  "
                  v-on:blur="validateInitials()"
                />
                <div id="applicant-id-error" class="ind-err-container">
                  <div
                    v-if="missingInitials"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-initials-error${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter your initials
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-40" />
          </div>

          <!-- submit button -->
          <div class="btn-container">
            <button
              type="button"
              class="back-btn"
              @click="handleBack()"
              data-test="nav-back"
            >
              <img
                class="back-icon"
                srcset="
                  @/assets/images/backIcon.png    1x,
                  @/assets/images/backIcon-2x.png 2x
                "
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              class="continue-btn"
              id="ll-continue-btn"
              type="button"
              @click="handleSubmit()"
              data-test="nav-continue"
            >
              <span class="continue-text">Submit</span>
            </button>
          </div>
          <div class="v-spacer-40" />

          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul
              v-if="systemErrorArray && systemErrorArray.length > 0"
              class="sys-err-list"
            >
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div>
        </section>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
import Api from "@/app/ll-commercial-api";
import Header from "@/components/Header";
import MainId from "@/components/MainId";
import Footer from "@/components/Footer";
import Breadcrumb_personalFi from "@/views/business/personal_fi_statement/Breadcrumb_personalFi.vue";
import jQuery from "jquery";
import { mapActions, mapGetters } from "vuex";
import Utils from "@/app/utils";

export default {
  name: "Confirmation Page",
  components: {
    Header,
    MainId,
    Footer,
    Breadcrumb_personalFi,
  },
  data() {
    return {
      systemErrorMsg: "",

      errCnt: 0,
      missingAppSignature: false,
      missingInitials: false,
    };
  },
  created() {
    this.setCurrentPage(5);
  },
  computed: {
    ...mapGetters("personalFiStatement", [
      "currentPage",
      "type",
      "application_id",
      "jwt_token",
      "applicant_first_name",
      "applicant_last_name",
      "business_credit_id",
      "business_credit_owner_id",
    ]),
    signPlaceHolder: {
      get() {
        return `${this.applicant_first_name} ${this.applicant_last_name}`;
      },
    },
    appSignature: {
      get() {
        return this.$store.state.personalFiStatement.applicant.signature;
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAppSignature", value);
      },
    },
    appInitials: {
      get() {
        return this.$store.state.personalFiStatement.applicant.initials;
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAppInitials", value);
      },
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 });

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    document.getElementById("current_date").innerHTML =
      month + "/" + day + "/" + year;
    document.getElementById("current_date2").innerHTML =
      month + "/" + day + "/" + year;
  },
  methods: {
    ...mapActions("personalFiStatement", [
      "setCurrentPage",
      "setAppInitials",
      "setAppSignature",
      "setValidatedNavPage",
      "setSubmissionID",
    ]),
    async putPagePersonalFi(payload) {
      this.systemErrorMsg = "";
      this.systemErrorArray = [];

      const resp = await Api.putPagePersonalFi(
        this.application_id,
        payload,
        this.jwt_token
      );
      console.log("--- response ---");
      console.log(resp);

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          this.setSubmissionID(resp.data.response.submission_id);

          // sleep for a smooth transition
          await new Promise((r) => setTimeout(r, 400));
          if (this.business_credit_id && this.business_credit_id != "") {
            this.$store.commit(
              "businessCreditOwner/setAppID",
              this.business_credit_id
            );
            this.$store.commit(
              "businessCreditOwner/setOwnerID",
              this.business_credit_owner_id
            );
            // call credit app to redirect use through business application signature flow
            const respVerified = await Api.getCreditAppOwner(
              this.business_credit_id,
              this.business_credit_owner_id,
              localStorage.getItem("token")
            );
            // if successful go through business credit signature flow
            if (respVerified.status == 200) {
              this.$router.push({ name: "Business Owner Assets" });
            }
            // this.$router.push({ name: 'Business Status' })
          } else {
            this.$router.push({ name: "PersonalFi Submitted" });
          }

          Utils.removeProcessingMask("#ll-continue-btn");
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message;
          }

          if (
            resp.data.response.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message);
              this.systemErrorArray.push(x);
            }
          }
          Utils.removeProcessingMask("#ll-continue-btn");
        }
      }
    },
    handleBack() {
      this.$router.push({ name: "Annual Income And Expenses" });
    },
    handleSubmit() {
      Utils.addProcessingMask("#ll-continue-btn");

      this.validatePage();
      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length;

        if (!this.errCnt) {
          const payload = {
            page: this.currentPage,
            signature: this.appSignature,
            initials: this.appInitials,
          };

          this.putPagePersonalFi(payload);
        } else {
          this.setValidatedNavPage({ name: "personal", isValid: 0 });

          if (this.errCnt > 1) {
            jQuery("html, body").animate({
              scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
            });
          } else {
            let offset = 0;
            if (this.missingAppSignature)
              offset = jQuery("#applicant-signature").offset().top;

            jQuery("html, body").animate({ scrollTop: offset });
          }
          // remove processing
          Utils.removeProcessingMask("#ll-continue-btn");
        }
      });
    },
    validatePage() {
      this.validateAppSignature();
      this.validateAppInitial();
    },

    validateAppInitial() {
      this.missingInitials = this.appInitials ? false : true;
    },
    validateAppSignature() {
      this.missingAppSignature = this.appSignature ? false : true;
    },

    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.signature {
  background: $yellowFocus;
}

.signature-box {
  border: 2px solid #bbb;
  padding: 25px;
}

.signature.ll-textfield-signature {
  background-color: $redFaint;
}

.date-text {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.signature-spacing {
  width: 700px;
}

.date-spacing {
  width: 450px;
}

table {
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding-right: 15px;
  padding: 10px;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 2.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {
  }
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.ll-city {
  @include media-breakpoint-up(xl) {
    .tf-city {
      min-width: 380px;
    }
  }

  @include media-breakpoint-down(xl) {
    flex: 1;
    max-width: unset;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

table {
  td {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }
  .ll-textfield {
    float: none;
    height: 50px;
    width: calc(100% - 10px);
  }
}

.ll-textfield {
  float: none;
  height: 50px;
  width: calc(100% - 10px);
}
</style>
